<template>
  <v-app
    :class="{ 'pattern-bg': light, 'pattern-bg-dark': dark }"
    v-if="!loading"
  >
    <v-navigation-drawer
      v-if="menu != 'none'"
      v-model="drawer"
      v-resize="onResize"
      :mini-variant.sync="mini"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      class="elevation-2"
      app
      clipped
      width="300px"
    >
      <v-scroll-x-reverse-transition group hide-on-leave>
        <MenuDashBoard :key="0" v-if="menu === 'dashboard'" />
        <MenuFormulariosTrabalhos
          :key="1"
          v-if="menu === 'MenuFormulariosTrabalhos'"
        />
      </v-scroll-x-reverse-transition>
    </v-navigation-drawer>

    <v-app-bar
      class="elevation-2"
      :class="{ primary: light, 'panel-gradient-bg-dark': dark }"
      app
      clipped-left
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        @click:outside="drawer = !drawer"
        @keydown.esc="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <h4 class="text-h6">GESTÃO ADMINISTRATIVA</h4>
      <v-spacer></v-spacer>

      <v-tooltip v-if="isAdmin" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            icon
            v-bind="attrs"
            v-on="on"
            :to="{ path: '/admin' }"
          >
            <v-icon> mdi-shield-account </v-icon>
          </v-btn>
        </template>
        <span>Sistema Admin</span>
      </v-tooltip>

      <v-tooltip v-if="isColaborador" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            icon
            v-bind="attrs"
            v-on="on"
            :to="{ path: '/colaborador' }"
          >
            <v-icon> mdi-clipboard-account </v-icon>
          </v-btn>
        </template>
        <span>Sistema Colaborador</span>
      </v-tooltip>

      <v-badge
        class="mr-2"
        :content="qtde_msgs_solicitacoes"
        :value="qtde_msgs_solicitacoes"
        color="green"
        overlap
        offset-x="25"
        offset-y="25"
      >
        <v-btn icon :to="{ path: '/gestor/solicitacao-servico' }">
          <v-icon> mdi-frequently-asked-questions </v-icon>
        </v-btn>
      </v-badge>

      <v-badge
        class="mr-2"
        :content="qtde_msgs_comentarios"
        :value="qtde_msgs_comentarios"
        color="green"
        overlap
        offset-x="25"
        offset-y="25"
      >
        <v-btn icon :to="{ path: '/gestor/comentarios' }">
          <v-icon> mdi-comment-text-multiple-outline </v-icon>
        </v-btn>
      </v-badge>

      <!-- <v-badge
        class="mr-8"
        :content="count_notificacao"
        :value="count_notificacao"
        color="green"
        overlap
        offset-x="25"
        offset-y="25"
      >
        <v-btn icon @click="toGoNotificacao">
          <v-icon>
            mdi-bell
          </v-icon>
        </v-btn>
      </v-badge> -->

      <div class="d-flex justify-center align-center mr-3">
        <v-avatar size="40">
          <v-img
            contain
            :src="
              store_avatar
                ? store_avatar
                : require('@/assets/avatar_placeholder.png')
            "
          >
          </v-img>
        </v-avatar>

        <div class="ml-2 text-body-1">{{ usuario_name }}</div>
      </div>

      <v-btn dark icon @click="menuUsuario = !menuUsuario">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- menu lateral direto -->
    <MenuLateralDireito v-if="menuUsuario" :menuUsuario.sync="menuUsuario" />

    <v-main style="z-index: 1">
      <transition mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-footer
      height="30px"
      app
      inset
      dark
      class="elevation-2"
      :class="{ primary: light, 'panel-gradient-bg-dark': dark }"
    >
    </v-footer>

    <v-card
      tile
      flat
      width="100px"
      height="100px"
      style="background: #eeeeee; position: fixed; right: 0; bottom: 22px"
    >
      <v-img :src="require('@/assets/logo_bg.png')"></v-img>
    </v-card>
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container class="fill-height align-center justify-center">
        <v-progress-circular
          :width="5"
          indeterminate
          :color="dark ? 'white' : 'primary'"
          size="80"
        ></v-progress-circular>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "GestorLayout",
  components: {
    MenuLateralDireito: () => import("../components/MenuLateralDireito"),
    MenuDashBoard: () => import("./menus/MenuDashBoard"),
    MenuFormulariosTrabalhos: () => import("./menus/MenuFormulariosTrabalhos"),
  },
  data() {
    return {
      drawer: true,
      menuUsuario: false,
      mini: true,
      locale: "",
      windowSize: {
        x: 0,
        y: 0,
      },
      apiUrl: null,
      dialogProfile: false,
      menu: "",
      loading: false,
    };
  },

  computed: {
    year() {
      return format(new Date(), "yyyy");
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
      menuDinamico: (state) => state.menuDinamico,
    }),
    ...mapGetters("Usuario", ["isAdmin", "isColaborador"]),
    ...mapState("Usuario", {
      usuario_name: (state) => state.usuario.name,
      store_avatar: (state) => state.avatar,
    }),
    // ...mapState("Notificacoes", {
    //   count_notificacao: (state) => state.count_notificacao,
    // }),
    ...mapState("Comentarios", {
      qtde_msgs_comentarios: (state) => state.qtde_msgs_comentarios,
    }),
    ...mapState("Solicitacoesservicos", {
      qtde_msgs_solicitacoes: (state) => state.qtde_msgs_solicitacoes,
    }),
    nome() {
      let splitName = this.usuario_name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      firstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      lastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      let name = firstName + " " + lastName;
      return name;
    },
  },

  watch: {
    $route() {
      if (this.$route.meta.menu) {
        this.menu = this.$route.meta.menu;
      }
    },
    menuDinamico() {
      if (this.menuDinamico) {
        this.menu = this.menuDinamico;
      }
    },
  },

  methods: {
    ...mapActions("Notificacoes", {
      STORE_count: "count",
    }),
    ...mapActions("Comentarios", {
      STORE_count_comentarios: "countComentarios",
    }),
    ...mapActions("Solicitacoesservicos", {
      STORE_count_solicitacoes_servicos: "countSolicitacoesServicos",
    }),
    toGoNotificacao() {
      this.$router.push("/gestor/notificacoes");
    },
    deslogarUsuario() {
      this.loading = true;
      this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
        this.$router.push("/login");
        this.loading = false;
      });
    },
    setTemplate() {
      if (this.light) {
        this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        this.$ls.set("template", "dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        this.$ls.set("template", "light");
        this.$vuetify.theme.dark = false;
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },

  mounted() {
    this.STORE_count();
    this.STORE_count_comentarios();
    this.STORE_count_solicitacoes_servicos();

    if (this.$route.meta.menu) {
      this.menu = this.$route.meta.menu;
    } else {
      this.menu = "dashboard";
    }
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }

    this.onResize();
    this.locale = this.$i18n.locale;
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: #eeeeee !important;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
